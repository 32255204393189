import { Wrapper } from '@googlemaps/react-wrapper'
import { MAPS_API_KEY } from '@env'
import { createCustomEqual } from 'fast-equals'
import { SIDEBAR_WIDTH } from './Sidebar'
import {
    useEffect,
    useState,
    useRef,
    Children,
    isValidElement,
    cloneElement
  } from 'react'

const Map = ({
    onClick,
    onIdle,
    onZoomChanged,
    onBoundsChanged,
    children,
    style,
    isWideScreen,
    ...options
  }) => {
    const ref = useRef(null)
    const [map, setMap] = useState()
  
    useEffect(() => {
      if (ref.current && !map) {
        const newMap = new window.google.maps.Map(ref.current, {})
  
        /*const bounds = {
          17: [
            [20969, 20970],
            [50657, 50658],
          ],
          18: [
            [41939, 41940],
            [101315, 101317],
          ],
          19: [
            [83878, 83881],
            [202631, 202634],
          ],
          20: [
            [167757, 167763],
            [405263, 405269],
          ],
        };
  
        const imageMapType = new google.maps.ImageMapType({
          getTileUrl: function (coord, zoom) {
            if (
              zoom < 17 ||
              zoom > 20 ||
              bounds[zoom][0][0] > coord.x ||
              coord.x > bounds[zoom][0][1] ||
              bounds[zoom][1][0] > coord.y ||
              coord.y > bounds[zoom][1][1]
            ) {
              console.log('zoom', zoom)
              console.log('coord', coord)
              return '';
            }
            console.log('match zoom', zoom)
            console.log('match coord', coord)
            return [
              'https://www.gstatic.com/io2010maps/tiles/5/L2_',
              zoom,
              '_',
              coord.x,
              '_',
              coord.y,
              '.png',
            ].join('');
          },
          tileSize: new google.maps.Size(256, 256),
        });
      
        newMap.overlayMapTypes.push(imageMapType);*/
  
        setMap(newMap)
      }
    }, [ref, map])
  
    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    useDeepCompareEffectForMaps(() => {
      if (map) {
        const position = isWideScreen ?
          google.maps.ControlPosition.TOP_LEFT :
          google.maps.ControlPosition.TOP_CENTER
        map.setOptions({
          ...options,
          mapTypeControlOptions: {
            position,
          },
        })
      }
    }, [map, options, isWideScreen])
  
    useEffect(() => {
      if (map) {
        ['click', 'idle'].forEach((eventName) =>
          google.maps.event.clearListeners(map, eventName)
        );
  
        if (onClick) {
          map.addListener('click', onClick)
        }
  
        if (onIdle) {
          map.addListener('idle', () => onIdle(map))
        }
  
        if (onZoomChanged) {
          map.addListener('zoom_changed', () => onZoomChanged(map.getZoom()))
        }
  
        if (onBoundsChanged) {
          map.addListener('bounds_changed', () => onBoundsChanged(map.getBounds()))
        }
      }
    }, [map])
  
    return (
      <>
        <div ref={ref} style={style} />
        {Children.map(children, child => {
          if (isValidElement(child)) {
            return cloneElement(child, { map });
          }
        })}
      </>
    );
  };


const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a, b) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b))
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  }
);

function useDeepCompareMemoize(value) {
  const ref = useRef()

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

function useDeepCompareEffectForMaps(
  callback,
  dependencies
) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize))
}

export default Map