import { Wrapper } from '@googlemaps/react-wrapper'
import { MAPS_API_KEY } from '@env'
import { useState, useRef } from 'react'
import { SIDEBAR_WIDTH } from './Sidebar'
import Map from './Map'
import VehicleLine from './VehicleLine'
import StopMarker from './StopMarker'
import InfoWindow from './InfoWindow'
import VisibleVehicles from './VisibleVehicles'
import { BACKEND_ADDRESS } from '../services/config'

const WebMap = ({
    lines,
    vehicles,
    refreshPeriod,
    infoRefreshPeriod,
    isWideScreen,
    initialCenter,
    setCenter,
    initialZoom,
    setZoom
  }) => {
  const [bounds, setBounds] = useState({ maxLat: 0, minLat: 0, maxLng: 0, minLng: 0 })
  const [infoContent, setInfoContent] = useState('')
  const [infoAnchor, setInfoAnchorRaw] = useState(null)
  const [infoPosition, setInfoPositionRaw] = useState(null)
  const [infoOpen, setInfoOpen] = useState(false)
  const infoRefreshIntervalId = useRef(null)
  const setInfoAnchor = anchor => {
    clearInterval(infoRefreshIntervalId.current)
    setInfoPositionRaw(null)
    setInfoAnchorRaw(anchor)
  }
  const setInfoPosition = position => {
    clearInterval(infoRefreshIntervalId.current)
    setInfoAnchorRaw(null)
    setInfoPositionRaw(position)
  }

  return (
    <div style={{ height: '100%', width: '100%', position: 'fixed', left: isWideScreen ? SIDEBAR_WIDTH : 0 }}>
      <Wrapper apiKey={MAPS_API_KEY}>
        <Map
          center={initialCenter}
          zoom={initialZoom}
          style={{ flexGrow: '1', height: '100%' }}
          streetViewControl={false}
          isWideScreen={isWideScreen}
          onBoundsChanged={
            bounds => setBounds({
              maxLat: bounds.getNorthEast().lat(),
              minLat: bounds.getSouthWest().lat(),
              maxLng: bounds.getNorthEast().lng(),
              minLng: bounds.getSouthWest().lng()
            })
          }
          onZoomChanged={zoom => setZoom(zoom)}
          onIdle={map => setCenter({
            lat: map.getCenter().lat(),
            lng: map.getCenter().lng() 
          })}
        >
          {
            lines.paths.map(path => toVehicleLine({ path, setInfoContent, setInfoPosition, setInfoOpen }))
          }
          {
            lines.stops.map(stop => {
              return (
                <StopMarker
                  key={stop.name + stop.track + stop.lng.toString() + stop.lat.toString()}
                  position={{ lat: stop.lat, lng: stop.lng }}
                  title={stop.name + ', läge ' + stop.track}
                  info={stop.usedBy.map(vehicle => vehicle.vehicleName).join("<br/>")}
                  icon={{
                    url: BACKEND_ADDRESS + 'images/stops/' + stop.usedBy
                      .map(vehicle => vehicle.fgColor.substring(1) + '-' + vehicle.bgColor.substring(1))
                      .join('_') + '.png',
                    size: { width: 12, height: 12 },
                    scaledSize: { width: 12, height: 12 },
                    anchor: { x: 6, y: 6 },
                  }}
                  setInfoContent={setInfoContent}
                  setInfoAnchor={setInfoAnchor}
                  setInfoOpen={setInfoOpen}
                />)
            })
          }
          <VisibleVehicles
            vehicles={vehicles}
            bounds={bounds}
            refreshPeriod={refreshPeriod}
            infoRefreshIntervalId={infoRefreshIntervalId}
            setInfoContent={setInfoContent}
            setInfoAnchor={setInfoAnchor}
            setInfoOpen={setInfoOpen}
            infoRefreshPeriod={infoRefreshPeriod}
          />
          <InfoWindow
            content={infoContent}
            anchor={infoAnchor}
            position={infoPosition}
            open={infoOpen}
            setOpen={setInfoOpen}
            infoRefreshIntervalId={infoRefreshIntervalId}
          />
        </Map>
      </Wrapper>
    </div>
  )
}

const toVehicleLine = ({ path, ...options }) =>
  <VehicleLine
    key={pointToString(path.points[0]) + " " + pointToString(path.points[1])}
    path={path.points}
    parentPaths={path.usedBy}
    {...options}
  />

const pointToString = point => `(${point.lng}, ${point.lat})`

export default WebMap