import {
  Autocomplete,
  Box,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { useState } from "react"
import Show from "../services/show"

const SIDEBAR_WIDTH = 260

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

const Sidebar = ({ lineNames, lineFilter, setLineFilter, show, setShow, isWideScreen }) => {
  const [open, setOpen] = useState(false)

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setOpen(!open)
  }

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={toggleDrawer}
        sx={{
          position: 'absolute',
          top: 10,
          left: 22,
          zIndex: 'tooltip',
          color: '#666666',
          backgroundColor: 'white',
          "&:hover": {
            color: 'black',
            backgroundColor: "white" 
          },
          borderRadius: 0,
          visibility: isWideScreen || open ? 'hidden' : 'visible'
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        variant={isWideScreen ? "permanent" : "temporary"}
        open={open}
        onClose={toggleDrawer}
        sx={{
          width: SIDEBAR_WIDTH,
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
      >
        <DrawerHeader sx={{p:3}}>
          <Typography>Linjekarta</Typography>
        </DrawerHeader>
        <Box sx={{
            p: 3,
            height: "100%",
            display: "flex",
            flexDirection: "column"
          }}>
          <Autocomplete
            multiple
            value={lineFilter}
            onChange={(_, newValue) => setLineFilter(newValue)}
            id="tags-standard"
            options={lineNames}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Linjer" />
            )}
            noOptionsText={'Ingen matchning'}
            sx={{mb: 10}}
          />
          {
            show
            ? <FormControl sx={{mt: "auto"}}>
                <FormLabel id="demo-radio-buttons-group-label">Visa</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={Show.Both}
                  name="radio-buttons-group"
                  value={show}
                  onChange={event => setShow(event.target.value)}
                >
                  <FormControlLabel value={Show.Both} sx={{mr: 0, mb: -1}} control={<Radio />} label="Både linjer och fordon" />
                  <FormControlLabel value={Show.OnlyLines} sx={{mr: 0, mb: -1}} control={<Radio />} label="Bara linjer" />
                  <FormControlLabel value={Show.OnlyVehicles} sx={{mr: 0, mb: -1}} control={<Radio />} label="Bara fordon" />
                </RadioGroup>
              </FormControl>
            : <></>
          }
        </Box>
        <Box sx={{ p: 2, textAlign: "right" }}>
          <Typography variant="caption" sx={{color: "rgba(0, 0, 0, 0.6)"}}>Modapt AB</Typography>
        </Box>
      </Drawer>
    </>
  )
}

export { SIDEBAR_WIDTH, Sidebar }
