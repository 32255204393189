import axios from 'axios'
import { BACKEND_ADDRESS } from './config'

const getAll = async timeout => {
  const requestTime = Date.now()
  const vehicles = (await axios.get(BACKEND_ADDRESS + 'vehicles', { timeout })).data
  const responseTime = Date.now()
  return vehicles.map(vehicle => ({
    requestTime,
    responseTime,
    ...vehicle
  }))
}

const getLines = async () => {
  return (await axios.get(BACKEND_ADDRESS + 'routes')).data
}

const getMergedLines = async () => {
  return (await axios.get(BACKEND_ADDRESS + 'routes/merged')).data
}

const getLineNames = async () => {
  return (await axios.get(BACKEND_ADDRESS + 'routes/names')).data
}

const blogService = { getAll, getLines, getMergedLines, getLineNames }
export default blogService
