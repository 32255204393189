
function getPixelsPerCoordinate(map) {
  const bounds = map.getBounds()

  const latHeight = bounds.getNorthEast().lat() - bounds.getSouthWest().lat()
  const lngWidth = bounds.getNorthEast().lng() - bounds.getSouthWest().lng()

  const pixelsPerLat = map.getDiv().offsetHeight / latHeight
  const pixelsPerLng = map.getDiv().offsetWidth / lngWidth

  return {
    pixelsPerLat,
    pixelsPerLng
  }
}

export { getPixelsPerCoordinate }