// import MapView from 'react-native-maps'
// import { Marker, Polyline } from 'react-native-maps'
// import { StyleSheet, View, Dimensions } from 'react-native'

const NativeMap = ({
  routes,
  vehicles,
  refreshPeriod
}) => (<></>
  // <View style={styles.container}>
  //   <MapView
  //     initialRegion={{
  //       latitude: 57.706257,
  //       longitude: 11.963735,
  //       latitudeDelta: 0.0922,
  //       longitudeDelta: 0.0421,
  //     }}
  //     style={styles.map}>
  //     <>{
  //       // routes.map(route => {
  //       //   return (<Polyline
  //       //     key={route.name}
  //       //     coordinates={route.geometry}
  //       //     strokeColor={route.bgColor}
  //       //   />)
  //       // })
  //     }</>
  //     <>{
  //       routes.stops.map(stop => (
  //         <Marker
  //           key={stop.name + stop.lng.toString() + stop.lat.toString()}
  //           coordinate={{ latitude: stop.lat, longitude: stop.lng }}
  //           title={stop.name}
  //           image={{
  //             uri: 'https://shielded-lowlands-10096.herokuapp.com/images/stops/' + stop.usedBy
  //               .map(vehicle => vehicle.fgColor.substring(1) + '-' + vehicle.bgColor.substring(1))
  //               .join('_') + '.png',
  //           }}
  //         // anchor={{ x: 0.5, y: 0.5 }}
  //         />)
  //       )
  //     }</>
  //     <>{
  //       // Object.keys(vehicles).map(vehicleId => {
  //       //   const vehicle = vehicles[vehicleId]
  //       //   return (
  //       //     <Marker
  //       //       key={vehicleId}
  //       //       coordinate={{ latitude: vehicle.y / 1000000, longitude: vehicle.x / 1000000 }}
  //       //       title={vehicle.name}
  //       //       description={vehicle.prodclass}
  //       //       image={{
  //       //         uri: 'https://shielded-lowlands-10096.herokuapp.com/images/vehicles/' +
  //       //           encodeURIComponent(`${vehicle.name}-${vehicle.lcolor}-${vehicle.bcolor}.png`)
  //       //       }}
  //       //       anchor={{ x: 0.5, y: 0.5 }}
  //       //     />)
  //       // })
  //       // vehicles.map(vehicle => (
  //       //   <VehicleMarker
  //       //     key={vehicle.gid}
  //       //     vehicle={vehicle}
  //       //     refreshPeriod={refreshPeriod}
  //       //   />))
  //     }</>
  //   </MapView>
  // </View>
)

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     backgroundColor: '#fff',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   map: {
//     width: Dimensions.get('screen').width,
//     height: Dimensions.get('screen').height,
//   },
// });

export default NativeMap