import { useEffect, useState, useRef } from 'react'
import { Platform } from 'react-native'
import vehicleService from './services/vehicles'
import NativeMap from './components/NativeMap'
import LineMap from './components/LineMap'
import { Sidebar } from './components/Sidebar'
import { Box, CssBaseline } from '@mui/material'
import isWideScreen from './services/isWideScreen'
import Show from './services/show'
import {
  initializeCenterFromUrl,
  initializeZoomFromUrl,
  initializeShowFromUrl,
  initializeLineFilterFromUrl,
  updateUrlFromCenter,
  updateUrlFromZoom,
  updateUrlFromShow,
  updateUrlFromLineFilter,
} from './services/urlService'

const VEHICLE_UPDATE_PERIOD_MS = 2500
const VEHICLE_UPDATE_TIMEOUT = 2200
const INFO_UPDATE_PERIOD_MS = 500
const DEFAULT_LINE_FILTER = ['s1', 's6']
const DEFAULT_SHOW = Show.Both
const DEFAULT_ZOOM = 14
const DEFAULT_CENTER = { lat: 57.706257, lng: 11.963735 }

export default function App() {
  const [lineNames, setLineNames] = useState([])
  const [lines, setLines] = useState({
    stops: [],
    paths: []
  })
  const [lineFilter, setLineFilter] = useState(DEFAULT_LINE_FILTER)
  const [vehicles, setVehicles] = useState([])
  const [show, setShow] = useState()
  const [initialZoom, setInitialZoom] = useState(DEFAULT_ZOOM)
  const [zoom, setZoom] = useState()
  const [initialCenter, setInitialCenter] = useState(DEFAULT_CENTER)
  const [center, setCenter] = useState()
  const timeUntilNextRequest = useRef(VEHICLE_UPDATE_PERIOD_MS)

  initializeCenterFromUrl(setInitialCenter)
  updateUrlFromCenter(center, DEFAULT_CENTER)

  initializeZoomFromUrl(setInitialZoom)
  updateUrlFromZoom(zoom, DEFAULT_ZOOM)

  initializeShowFromUrl(setShow, DEFAULT_SHOW)
  updateUrlFromShow(show, DEFAULT_SHOW)

  initializeLineFilterFromUrl(setLineFilter)
  updateUrlFromLineFilter(lineFilter, DEFAULT_LINE_FILTER)

  useEffect(() => {
    vehicleService.getLineNames().then(names => setLineNames(names))
  }, [])

  const updateVehicles = () => {
    if (document.hidden) {
      return
    }
    const timeBeforeUpdate = Date.now()
    vehicleService.getAll(VEHICLE_UPDATE_TIMEOUT)
      .then(newVehicles => {
        timeUntilNextRequest.current = VEHICLE_UPDATE_PERIOD_MS - (Date.now() - timeBeforeUpdate)
        setVehicles(oldVehicles => {
          const oldPositions = new Map()
          oldVehicles.forEach(vehicle => oldPositions.set(vehicle.id, {oldLat: vehicle.lat, oldLng: vehicle.lng}))
          return newVehicles
          .map(newVehicle => ({
            ...newVehicle,
            oldLat: oldPositions.get(newVehicle.id)?.oldLat,
            oldLng: oldPositions.get(newVehicle.id)?.oldLng
          }))
        })
      })
  }

  useEffect(() => {
    updateVehicles()
    const interval = setInterval(() => {
      updateVehicles()
    }, VEHICLE_UPDATE_PERIOD_MS)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    vehicleService.getMergedLines().then(setLines)
  }, [])

  const longNameLineFilter = getLongNameFilter(lineFilter, lineNames)

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar
        lineNames={lineNames.map(lineName => lineName.fullName)}
        lineFilter={longNameLineFilter}
        setLineFilter={newFullNameFilter =>
          setLineFilter(getShortNameFilter(newFullNameFilter, lineNames))
        }
        show={show}
        setShow={setShow}
        isWideScreen={isWideScreen()}
      />
      <EarthMap
        lines={filterLines(longNameLineFilter, show, lines)}
        vehicles={show === Show.OnlyLines ? [] : filterVehicles(lineFilter, vehicles)}
        refreshPeriod={timeUntilNextRequest.current}
        infoRefreshPeriod={INFO_UPDATE_PERIOD_MS}
        isWideScreen={isWideScreen()}
        initialCenter={initialCenter}
        setCenter={setCenter}
        initialZoom={initialZoom}
        setZoom={setZoom}
      />
    </Box>
  )
}

function getLongNameFilter(shortLineNameFilter, lineNames) {
  return shortLineNameFilter.map(shortLineName => findShortLineName(lineNames, shortLineName))
    .filter(lineName => Boolean(lineName))
    .map(lineName => lineName.fullName)
}

function getShortNameFilter(fullNameLineFilter, lineNames) {
  return fullNameLineFilter.map(fullLineName => findFullLineName(lineNames, fullLineName))
    .filter(lineName => Boolean(lineName))
    .map(lineName => lineName.shortName)
}

const findShortLineName = (lineNames, shortLineName) =>
  lineNames.find(lineName => lineName.shortName === shortLineName)

const findFullLineName = (lineNames, fullLineName) =>
  lineNames.find(lineName => lineName.fullName === fullLineName)

const EarthMap = props => Platform.OS !== 'web' ?
  <NativeMap {...props} /> :
  <LineMap {...props} />

const filterLines = (filter, show, lines) =>
  show === Show.OnlyVehicles
    ? { stops: [], paths: [] }
    : {
      stops: filterOnUsedBy(filter, lines.stops),
      paths: filterOnUsedBy(filter, lines.paths)
    }

const filterOnUsedBy = (filter, items) =>
  filter.length == 0 ?
    items :
    items.map(item => (
      {
        ...item,
        usedBy: item.usedBy
          .filter(vehicle => filter.includes(vehicle.vehicleName))
      }))
      .filter(item => item.usedBy.length > 0)

const filterVehicles = (filter, vehicles) =>
  filter.length == 0 ?
    vehicles :
    vehicles.filter(vehicle => filter.includes(vehicle.shortName))
