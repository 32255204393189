import { useEffect, useState } from 'react'
import usePrevious from '../services/usePrevious'

const InfoWindow = ({ map, content, anchor, position, open, setOpen, infoRefreshIntervalId }) => {
  const [infoWindow, setInfoWindow] = useState()
  const previouslyOpened = usePrevious(open)
  const previousAnchor = usePrevious(anchor)
  const previousPosition = usePrevious(position)

  useEffect(() => {
    if (!infoWindow) {
      const newInfoWindow = new google.maps.InfoWindow()
      newInfoWindow.addListener('closeclick', () => {
        setOpen(false)
        clearInterval(infoRefreshIntervalId.current)
      })
      setInfoWindow(newInfoWindow)
    }
  }, [infoWindow])

  useEffect(() => {
    if (infoWindow) {
      infoWindow.setContent(content)
      if (open && !previouslyOpened || previousAnchor !== anchor || previousPosition !== position) {
        infoWindow.setPosition(position)
        infoWindow.open({
          anchor,
          map
        })
      } else if (!open) {
        infoWindow.close()
      }
    }
  }, [infoWindow, content, anchor, previousAnchor, position, previousPosition, open, previouslyOpened])
}

export default InfoWindow