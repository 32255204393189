import { useEffect, useState } from 'react'
import { createInfoBody } from '../services/infoService'

const VehicleLine = (options) => {
  const [polyline, setPolyline] = useState()

  useEffect(() => {
    if (!polyline) {
      setPolyline(new google.maps.Polyline())
    }

    // remove polyline from map on unmount
    return () => {
      if (polyline) {
        polyline.setMap(null)
      }
    };
  }, [polyline])

  const {parentPaths, setInfoContent, setInfoPosition, setInfoOpen} = options
  useEffect(() => {
    if (polyline) {
      google.maps.event.clearListeners(polyline, 'click')
      polyline.addListener("click", event => {
        setInfoContent(createInfoBody(parentPaths.map(p => p.vehicleName).join("<br/>")))
        setInfoPosition(event.latLng)
        setInfoOpen(true)
      })
    }
  }, [polyline, setInfoContent, setInfoPosition, setInfoOpen])

  useEffect(() => {
    if (polyline) {
      if (parentPaths.length == 1) {
        const strokeColor = parentPaths[0].bgColor < parentPaths[0].fgColor ?
          parentPaths[0].bgColor :
          parentPaths[0].fgColor
        polyline.setOptions({
          strokeColor,
          strokeOpacity: 1,
          strokeWeight: 3,
          icons: [],
          ...options
        })
      } else {
        polyline.setOptions({
          strokeOpacity: 0,
          icons: parentPaths.map((parent, index) => {
            const strokeColor = parent.bgColor < parent.fgColor ?
              parent.bgColor :
              parent.fgColor
            return {
              icon: {
                path: 'M 0,0 0,2',
                strokeOpacity: 1,
                strokeColor,
              },
              offset: index.toString() + '0px',
              repeat: parentPaths.length.toString() + '0px',
            }
          }),
          strokeWeight: 3,
          ...options
        })
      }
    }
  }, [polyline, options])

  return null
}

export default VehicleLine