import { useEffect, useState } from 'react'
import { createInfoTitle, createInfoBody } from '../services/infoService'

const StopMarker = (options) => {
  const [marker, setMarker] = useState()
  const {title, info, setInfoContent, setInfoAnchor, setInfoOpen} = options

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker())
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null)
      }
    };
  }, [marker])

  useEffect(() => {
    if (marker) {
      marker.setOptions({
        optimized: true,
        ...options
      })
      google.maps.event.clearListeners(marker, 'click')
      marker.addListener("click", () => {
        setInfoContent(createInfoTitle(title) + createInfoBody(info))
        setInfoAnchor(marker)
        setInfoOpen(true)
      })
    }
  }, [marker, options])

  return null
}

export default StopMarker