import { useEffect } from "react"
import Show from "./show"

const URL_FILTER_LIST_SEPARATOR = '-'

function initializeCenterFromUrl(setInitialCenter) {
  useEffect(() => {
    const params = getUrlParams()
    const urlLat = parseFloat(params.get('lat'))
    const urlLng = parseFloat(params.get('lng'))
    if (!isNaN(urlLat) && !isNaN(urlLng)) {
      setInitialCenter({ lat: urlLat, lng: urlLng })
    }
  }, [])
}

function initializeZoomFromUrl(setInitialZoom) {
  useEffect(() => {
    const params = getUrlParams()
    const urlZoom = parseInt(params.get('zoom'))
    if (!isNaN(urlZoom)) {
      setInitialZoom(urlZoom)
    }
  }, [])
}

function initializeShowFromUrl(setShow, defaultShow) {
  useEffect(() => {
    const params = getUrlParams()
    const urlShow = params.get('show')
    if (Object.values(Show).includes(urlShow)) {
      setShow(urlShow)
    } else {
      setShow(defaultShow)
    }
  }, [])
}

function initializeLineFilterFromUrl(setLineFilter) {
  useEffect(() => {
    const params = getUrlParams()
    const urlLineFilter = params.get('lines')
    if (urlLineFilter === '') {
      setLineFilter([])
      return
    } else if (!urlLineFilter) {
      return
    }
    setLineFilter(urlLineFilter.split(URL_FILTER_LIST_SEPARATOR))
  }, [])
}

function updateUrlFromCenter(center, defaultCenter) {
  useEffect(() => {
    if (!center) {
      return
    }
    const params = getUrlParams()
    const lat = roundToCoordinatePrecision(center.lat)
    const lng = roundToCoordinatePrecision(center.lng)
    if (defaultCenter.lat === lat && defaultCenter.lng === lng) {
      params.delete('lat')
      params.delete('lng')
    } else {
      params.set('lat', lat)
      params.set('lng', lng)
    }
    updateUrlWith(params)
  }, [center])
}

function updateUrlFromZoom(zoom, defaultZoom) {
  useEffect(() => {
    if (!zoom) {
      return
    }
    const params = getUrlParams()
    zoom === defaultZoom
      ? params.delete('zoom')
      : params.set('zoom', zoom)
    updateUrlWith(params)
  }, [zoom])
}

function updateUrlFromShow(show, defaultShow) {
  useEffect(() => {
    if (!show) {
      return
    }
    const params = getUrlParams()
    show === defaultShow
      ? params.delete('show')
      : params.set('show', show)
    updateUrlWith(params)
  }, [show])
}

function updateUrlFromLineFilter(filter, defaultFilter) {
  useEffect(() => {
    if (filter === defaultFilter) {
      return
    }
    const params = getUrlParams()
    params.set('lines', filter.join(URL_FILTER_LIST_SEPARATOR))
    updateUrlWith(params)
  }, [filter])
}

function getUrlParams() {
  return new URLSearchParams(window.location.search)
}

function updateUrlWith(params) {
  const basePath = window.location.pathname
  const urlParams = params.toString()
  const newUrl = urlParams ? basePath + '?' + urlParams : basePath
  history.replaceState(null, '', newUrl)
}

const roundToCoordinatePrecision = num =>
  Math.round(num * 1000000 + Number.EPSILON) / 1000000

export {
  initializeCenterFromUrl,
  initializeZoomFromUrl,
  initializeShowFromUrl,
  initializeLineFilterFromUrl,
  updateUrlFromCenter,
  updateUrlFromZoom,
  updateUrlFromShow,
  updateUrlFromLineFilter,
}