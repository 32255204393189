import { getPixelsPerCoordinate } from "../services/mapService"
import { VehicleMarker, ICON_RADIUS } from "./VehicleMarker"

const VisibleVehicles = ({map, vehicles, bounds, ...otherProps}) => {
  const visibleVehicles = map && map.getBounds() ? filterOnBounds(vehicles, map, bounds) : []
  return (
    <>
      {
        visibleVehicles.map(vehicle => (
          <VehicleMarker
            key={vehicle.id}
            vehicle={vehicle}
            map={map}
            {...otherProps}
          />))
      }
    </>
  )
}

function filterOnBounds(vehicles, map, bounds) {
  const { pixelsPerLat, pixelsPerLng } = getPixelsPerCoordinate(map)
  const latIconRadius = ICON_RADIUS / pixelsPerLat
  const lngIconRadius = ICON_RADIUS / pixelsPerLng

  const iconBoundsMinLat = bounds.minLat - latIconRadius
  const iconBoundsMaxLat = bounds.maxLat + latIconRadius
  const iconBoundsMinLng = bounds.minLng - lngIconRadius
  const iconBoundsMaxLng = bounds.maxLng + lngIconRadius

  return vehicles.filter(vehicle =>
    vehicle.lat > iconBoundsMinLat
    && vehicle.lat < iconBoundsMaxLat
    && vehicle.lng > iconBoundsMinLng
    && vehicle.lng < iconBoundsMaxLng
    || vehicle.oldLat > iconBoundsMinLat
    && vehicle.oldLat < iconBoundsMaxLat
    && vehicle.oldLng > iconBoundsMinLng
    && vehicle.oldLng < iconBoundsMaxLng)
}

export default VisibleVehicles