import { useEffect, useState, useRef } from 'react'
import { createInfoTitle, createInfoBody } from '../services/infoService'
import { getPixelsPerCoordinate } from '../services/mapService'
import { BACKEND_ADDRESS } from '../services/config'

const ICON_RADIUS = 12
const ICON_DIAMETER = ICON_RADIUS * 2

const VehicleMarker = (options) => {
  const [marker, setMarker] = useState()

  useEffect(() => {
    if (!marker) {
      const newMarker = new google.maps.Marker()
      const startPosition = hasOldPosition(vehicle)
        ? getAnimatedPosition(vehicle, options.refreshPeriod)
        : { lat: vehicle.lat, lng: vehicle.lng }
      newMarker.setOptions({
        title: vehicle.fullName,
        position: startPosition,
        zIndex: 2,
        optimized: false,
        icon: {
          url: BACKEND_ADDRESS + 'images/vehicles/' + vehicle.icon,
          size: { width: ICON_DIAMETER, height: ICON_DIAMETER },
          scaledSize: { width: ICON_DIAMETER, height: ICON_DIAMETER },
          anchor: { x: ICON_RADIUS, y: ICON_RADIUS },
        },
        map: options.map
      })
      setMarker(newMarker)
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null)
      }
    };
  }, [marker])

  const { vehicle, infoRefreshIntervalId, setInfoContent, setInfoAnchor, setInfoOpen, infoRefreshPeriod } = options
  const toSeconds = ms => Math.round(ms / 1000)
  const infoContent = useRef(_ => '')
  infoContent.current = now => createInfoTitle(vehicle.fullName)
    + createInfoBody(
      getDelayMessage(vehicle) + "<br/>"
      + `koordinat: ${vehicle.lat}, ${vehicle.lng}<br/>`
      + `riktning: ${vehicle.direction}<br/>`
      + `id: ${vehicle.id}<br/>`
      + `tid sen anrop: ${toSeconds(now - vehicle.requestTime)} s<br/>`
      + `tid sen svar: ${toSeconds(now - vehicle.responseTime)} s`)
  useEffect(() => {
    if (marker) {
      google.maps.event.clearListeners(marker, 'click')
      marker.addListener("click", () => {
        setInfoContent(infoContent.current(Date.now()))
        setInfoAnchor(marker)
        setInfoOpen(true)
        infoRefreshIntervalId.current = setInterval(() => {
          setInfoContent(infoContent.current(Date.now()))
        }, infoRefreshPeriod)
      })
    }
  }, [marker, setInfoContent, setInfoAnchor, setInfoOpen])

  useEffect(() => {
    if (!marker || !hasOldPosition(vehicle)) {
      return
    }

    const dLat = vehicle.lat - vehicle.oldLat
    const dLng = vehicle.lng - vehicle.oldLng
    
    const { pixelsPerLat, pixelsPerLng } = getPixelsPerCoordinate(options.map)
    const dPixelsX = Math.round(dLng * pixelsPerLng)
    const dPixelsY = Math.round(dLat * pixelsPerLat)

    const maxPixelMovement = Math.abs(dPixelsX) + Math.abs(dPixelsY)
    const refreshPeriod = options.refreshPeriod
    const maxRedrawsPerSecond = 60
    const maxRedraws = (refreshPeriod / 1000) * maxRedrawsPerSecond

    const redraws = Math.min(maxPixelMovement, maxRedraws)

    if (redraws === 0) {
      return
    }

    if (redraws === 1) {
      marker.setPosition({
        lat: vehicle.lat,
        lng: vehicle.lng
      })
    } else {
      const redrawTime = refreshPeriod / redraws
      const interval = setInterval(() => {
        marker.setPosition(getAnimatedPosition(vehicle, refreshPeriod))
      }, redrawTime)

      const timer = setTimeout(() => {
        clearInterval(interval)
        marker.setPosition({
          lat: vehicle.lat,
          lng: vehicle.lng
        })
      }, vehicle.responseTime + refreshPeriod - Date.now() + 100) // Add some extra time to not timeout exactly on the update
      return () => {
        clearInterval(interval)
        clearTimeout(timer)
      }
    }
  }, [marker, vehicle])
}

function hasOldPosition(vehicle) {
  return Boolean(vehicle.oldLat)
}

function getAnimatedPosition(vehicle, refreshPeriod) {
  const animationStartTime = vehicle.responseTime
  const animationEndTime = vehicle.responseTime + refreshPeriod
  const currentTime = Date.now()
  const timeSinceAnimationStart = currentTime - animationStartTime
  const timeUntilAnimationEnd = animationEndTime - currentTime
  const lat = (vehicle.lat * timeSinceAnimationStart + vehicle.oldLat * timeUntilAnimationEnd) / refreshPeriod
  const lng = (vehicle.lng * timeSinceAnimationStart + vehicle.oldLng * timeUntilAnimationEnd) / refreshPeriod
  return {lat, lng}
}

function getDelayMessage(vehicle) {
  const delay = parseInt(vehicle.delay)
  if (delay === 1) {
    return `försenad 1 minut`
  } else if (delay > 0) {
    return `försenad ${delay} minuter`
  } else if (delay === -1) {
    return `1 minut före tidtabellen`
  } else if (delay < 0) {
    return `${-delay} minuter före tidtabellen`
  } else {
    return "i tid"
  }
}

export { VehicleMarker, ICON_RADIUS }